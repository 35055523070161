// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-charities-js": () => import("./../../../src/pages/charities.js" /* webpackChunkName: "component---src-pages-charities-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interviews-js": () => import("./../../../src/pages/interviews.js" /* webpackChunkName: "component---src-pages-interviews-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-templates-charity-item-jsx": () => import("./../../../src/templates/charity-item.jsx" /* webpackChunkName: "component---src-templates-charity-item-jsx" */),
  "component---src-templates-education-item-jsx": () => import("./../../../src/templates/education-item.jsx" /* webpackChunkName: "component---src-templates-education-item-jsx" */),
  "component---src-templates-interview-item-jsx": () => import("./../../../src/templates/interview-item.jsx" /* webpackChunkName: "component---src-templates-interview-item-jsx" */),
  "component---src-templates-partner-item-jsx": () => import("./../../../src/templates/partner-item.jsx" /* webpackChunkName: "component---src-templates-partner-item-jsx" */)
}

